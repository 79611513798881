import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

const apiClient = axios.create({
  baseURL: 'https://api.skytimer.app/aircraft/',
  headers: {
    'x-api-key': '1915CABA5363DB7A7BE3823485DE5'
  }
});

function AircraftDetail({ aircraftId, onUpdate, onDelete }) {
  const [aircraft, setAircraft] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [notes, setNotes] = useState('');
  const [variant, setVariant] = useState('');
  const [manufacturer, setManufacturer] = useState('');
  const [model, setModel] = useState('');
  const [isEditing, setIsEditing] = useState({ manufacturer: false, model: false });
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [expandedSections, setExpandedSections] = useState({ engines: false, tanks: false, selectors: false, profiles: false });

  useEffect(() => {
    const fetchAircraft = async () => {
      setIsLoading(true);
      try {
        const response = await apiClient.get(`/templates/${aircraftId}`);
        const aircraftData = response.data;
        setAircraft(aircraftData);
        setNotes(aircraftData.notes || '');
        setVariant(aircraftData.variant || '');
        setManufacturer(aircraftData.manufacturer || '');
        setModel(aircraftData.model || '');
      } catch (err) {
        setError(err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchAircraft();
  }, [aircraftId]);

  const toggleStatus = async (statusType) => {
    const updatedStatus = !aircraft[statusType];
    const updatedAircraft = {
      ...aircraft,
      [statusType]: updatedStatus
    };

    setAircraft(updatedAircraft);
    onUpdate(updatedAircraft);

    try {
      await apiClient.put(`/templates/${aircraftId}`, updatedAircraft);
    } catch (err) {
      console.error(`Error updating aircraft: ${err}`);
      setError(err);
      setAircraft(aircraft); // Rollback on error
      onUpdate(aircraft); // Notify parent to rollback
    }
  };

  const handleDelete = async () => {
    try {
      await apiClient.delete(`/templates/${aircraftId}`);
      onDelete(aircraftId);
    } catch (err) {
      console.error(`Error deleting aircraft: ${err}`);
      setError(err);
    }
  };

  const handleSave = async () => {
    const updatedAircraft = {
      ...aircraft,
      notes,
      variant,
      manufacturer,
      model
    };

    setAircraft(updatedAircraft);
    onUpdate(updatedAircraft);

    try {
      await apiClient.put(`/templates/${aircraftId}`, updatedAircraft);
      setShowConfirmation(true);
      setTimeout(() => setShowConfirmation(false), 3000);
    } catch (err) {
      console.error(`Error updating aircraft: ${err}`);
      setError(err);
      setAircraft(aircraft); // Rollback on error
      onUpdate(aircraft); // Notify parent to rollback
    }
  };

  const handleEditToggle = (field) => {
    setIsEditing({ ...isEditing, [field]: !isEditing[field] });
  };

  const handleDuplicate = async () => {
    const newId = uuidv4();
    const newAircraft = {
      ...aircraft,
      id: newId,
      variant: `${aircraft.variant} copy`,
      isHidden: false,
      isTest: false,
      isSelected: false
    };

    try {
      await apiClient.put(`/templates/${newId}`, newAircraft);
      onUpdate(newAircraft);
      // Optionally, trigger a refresh of the aircraft list in the parent component
    } catch (err) {
      console.error(`Error duplicating aircraft: ${err}`);
      setError(err);
    }
  };

  const toggleSection = (section) => {
    setExpandedSections({ ...expandedSections, [section]: !expandedSections[section] });
  };

  const createLookupTable = () => {
    const lookupTable = {};

    if (aircraft?.aircraft?.fuelTanks) {
      aircraft.aircraft.fuelTanks.forEach(tank => {
        lookupTable[tank.id] = tank.label;
      });
    }

    if (aircraft?.aircraft?.engines) {
      aircraft.aircraft.engines.forEach(engine => {
        lookupTable[engine.id] = engine.location;
      });
    }

    return lookupTable;
  };

  const lookupTable = createLookupTable();

  const getLabelById = (id) => {
    return lookupTable[id] || id;
  };

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!aircraft) {
    return <div>No aircraft data found.</div>;
  }

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  return (
    <div className="aircraft-detail">
      <h2>
        {isEditing.manufacturer ? (
          <input
            type="text"
            value={manufacturer}
            onChange={(e) => setManufacturer(e.target.value)}
            onBlur={() => handleEditToggle('manufacturer')}
          />
        ) : (
          <span onClick={() => handleEditToggle('manufacturer')}>
            {aircraft.manufacturer}
          </span>
        )}{" "}
        {isEditing.model ? (
          <input
            type="text"
            value={model}
            onChange={(e) => setModel(e.target.value)}
            onBlur={() => handleEditToggle('model')}
          />
        ) : (
          <span onClick={() => handleEditToggle('model')}>
            {aircraft.model}
          </span>
        )}
      </h2>
      <p><strong>ICAO:</strong> {aircraft.icao}</p>
      <p><strong>Engine Time Type:</strong> {aircraft.aircraft.engineTimeType}</p>
      <p><strong>Stall Speed:</strong> {aircraft.aircraft.stallSpeed} knots</p>
      <p><strong>Last Updated:</strong> {formatDate(aircraft.lastUpdated)}</p>
      
      <div className="form-group">
        <label>
          Variant:
          <input
            type="text"
            value={variant}
            onChange={(e) => setVariant(e.target.value)}
          />
        </label>
      </div>
      <div className="form-group">
        <label>
          Notes:
          <textarea
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
          />
        </label>
      </div>

      <div className="section">
        <h3 onClick={() => toggleSection('engines')}>Engines {expandedSections.engines ? '▼' : '▶'}</h3>
        {expandedSections.engines && aircraft.aircraft.engines && (
          <div>
            {aircraft.aircraft.engines.map(engine => (
              <div key={engine.id}>
                <p><strong>Location:</strong> {engine.location}</p>
                <p><strong>Fuel Flows:</strong></p>
                <ul>
                  {Object.entries(engine.fuelFlows).map(([key, value]) => (
                    <li key={key}><strong>{key}:</strong> {value}</li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        )}
      </div>

      <div className="section">
        <h3 onClick={() => toggleSection('tanks')}>Fuel Tanks {expandedSections.tanks ? '▼' : '▶'}</h3>
        {expandedSections.tanks && aircraft.aircraft.fuelTanks && (
          <div>
            {aircraft.aircraft.fuelTanks.map(tank => (
              <div key={tank.id}>
                <p><strong>Label:</strong> {tank.label}</p>
                <p><strong>Total Capacity:</strong> {tank.totalCapacity}</p>
              </div>
            ))}
          </div>
        )}
      </div>

      <div className="section">
        <h3 onClick={() => toggleSection('selectors')}>Fuel Selectors {expandedSections.selectors ? '▼' : '▶'}</h3>
        {expandedSections.selectors && aircraft.aircraft.fuelSelectors && (
          <div>
            {aircraft.aircraft.fuelSelectors.map(selector => (
              <div key={selector.id}>
                <p><strong>Type:</strong> {selector.type}</p>
                <p><strong>Components:</strong></p>
                <ul>
                  {selector.components.map((component, index) => (
                    <li key={index}>
                      {getLabelById(component)}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        )}
      </div>

      <div className="section">
        <h3 onClick={() => toggleSection('profiles')}>Fuel Profiles {expandedSections.profiles ? '▼' : '▶'}</h3>
        {expandedSections.profiles && aircraft.aircraft.fuelProfiles && (
          <div>
            {aircraft.aircraft.fuelProfiles.map(profile => (
              <div key={profile.id}>
                <p><strong>Name:</strong> {profile.name}</p>
                <p><strong>Fuel Endurance Alert:</strong> {profile.fuelEnduranceAlert}</p>
                <p><strong>Initial Configuration:</strong></p>
                <ul>
                  {profile.initialConfiguration.map((config, index) => (
                    <li key={index}>
                      {typeof config === 'string' ? getLabelById(config) : JSON.stringify(config)}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        )}
      </div>

      <div className="button-group">
        <button className="save-button" onClick={handleSave}>Save Changes</button>
        <button className="duplicate-button" onClick={handleDuplicate}>Duplicate Aircraft</button>
        <button className="selected-button" onClick={() => toggleStatus('isSelected')}>
          {aircraft.isSelected ? 'Remove from Selected' : 'Add to Selected'}
        </button>
        <button className="test-button" onClick={() => toggleStatus('isTest')}>
          {aircraft.isTest ? 'Remove from Test' : 'Add to Test'}
        </button>
        {!aircraft.isSelected && !aircraft.isTest && (
          <button className="delete-button" onClick={handleDelete}>
            Delete Aircraft
          </button>
        )}
        {!aircraft.isSelected && !aircraft.isTest && (
          <button className="hide-button" onClick={() => toggleStatus('isHidden')}>
            {aircraft.isHidden ? 'Unhide' : 'Hide'}
          </button>
        )}
      </div>
      {showConfirmation && <p className="confirmation">Changes saved successfully!</p>}
    </div>
  );
}

export default AircraftDetail;
