import React, { useEffect, useState } from 'react';
import { Amplify } from 'aws-amplify';
import { withAuthenticator } from '@aws-amplify/ui-react';
import { signOut } from 'aws-amplify/auth';
import axios from 'axios';
import awsconfig from './aws-exports';
import AircraftDetail from './AircraftDetail';
import './App.css';
import '@aws-amplify/ui-react/styles.css';

Amplify.configure(awsconfig);

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolClientId: '473tkp23q60tgq18gdtvpo7c69',
      userPoolId: 'us-east-1_VFrbbUSjo',
    }
  }
});

const apiClient = axios.create({
  baseURL: 'https://api.skytimer.app/aircraft/',
  headers: {
    'x-api-key': '1915CABA5363DB7A7BE3823485DE5'  // Add your API key here
  }
});

async function handleSignOut() {
  await signOut();
}

function App() {
  const [aircrafts, setAircrafts] = useState([]);
  const [filteredAircrafts, setFilteredAircrafts] = useState([]);
  const [selectedAircraftId, setSelectedAircraftId] = useState(null);
  const [filter, setFilter] = useState('all');
  const [showHidden, setShowHidden] = useState(true);

  const fetchAircrafts = async () => {
    try {
      const response = await apiClient.get('/templates');
      setAircrafts(response.data.sort((a, b) => new Date(b.lastUpdated) - new Date(a.lastUpdated)));
      setFilteredAircrafts(response.data.sort((a, b) => new Date(b.lastUpdated) - new Date(a.lastUpdated)));
    } catch (err) {
      console.error('Error fetching aircraft templates:', err);
    }
  };

  useEffect(() => {
    fetchAircrafts();
  }, []);

  useEffect(() => {
    applyFilter();
  }, [filter, showHidden, aircrafts]);

  const applyFilter = () => {
    let filtered = aircrafts.filter(aircraft => showHidden || !aircraft.isHidden);
    switch (filter) {
      case 'selected':
        filtered = filtered.filter(aircraft => aircraft.isSelected);
        break;
      case 'test':
        filtered = filtered.filter(aircraft => aircraft.isTest);
        break;
      case 'notes':
        filtered = filtered.filter(aircraft => aircraft.notes && aircraft.notes.trim() !== '');
        break;
      default:
        break;
    }
    setFilteredAircrafts(filtered);
  };

  const handleAircraftUpdate = (updatedAircraft) => {
    setAircrafts(aircrafts.map(aircraft =>
      aircraft.id === updatedAircraft.id ? updatedAircraft : aircraft
    ));
  };

  const handleAircraftDelete = (aircraftId) => {
    setAircrafts(aircrafts.filter(aircraft => aircraft.id !== aircraftId));
  };

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  return (
    <div className="app-container">
      <div className="sidebar">
        <button type="button" className="signout-button" onClick={handleSignOut}>
          Sign out
        </button>
        <h1>Aircraft Templates</h1>
        <div className="filter-container">
          <div className="filter-group">
            <label>
              Filter:
              <select value={filter} onChange={(e) => setFilter(e.target.value)}>
                <option value="all">All</option>
                <option value="selected">Selected</option>
                <option value="test">Test</option>
                <option value="notes">With Notes</option>
              </select>
            </label>
          </div>
          <button type="button" className="toggle-hidden-button" onClick={() => setShowHidden(!showHidden)}>
            {showHidden ? 'Hide Hidden' : 'Show Hidden'}
          </button>
        </div>
        <ul className="aircraft-list">
          {filteredAircrafts.map(aircraft => (
            <li key={aircraft.id} onClick={() => setSelectedAircraftId(aircraft.id)}>
              <div className="aircraft-info">
                <div>
                  {aircraft.manufacturer} {aircraft.model}
                </div>
                <div className="aircraft-details">
                  <span className="aircraft-variant">{aircraft.variant}</span>
                  <span className="aircraft-date">{formatDate(aircraft.lastUpdated)}</span>
                </div>
              </div>
              <div className="aircraft-icons">
                <span className="aircraft-icon">
                  {aircraft.isSelected && <span role="img" aria-label="selected">✅</span>}
                </span>
                <span className="aircraft-icon">
                  {aircraft.isTest && <span role="img" aria-label="test">🚀</span>}
                </span>
                <span className="aircraft-icon">
                  {aircraft.notes && <span role="img" aria-label="notes">📝</span>}
                </span>
              </div>
            </li>
          ))}
        </ul>
      </div>
      <div className="details">
        {selectedAircraftId && <AircraftDetail aircraftId={selectedAircraftId} onUpdate={handleAircraftUpdate} onDelete={handleAircraftDelete} onDuplicate={fetchAircrafts} />}
      </div>
    </div>
  );
}

export default withAuthenticator(App, { hideSignUp: true });
